export const initialColumns = [
  {
    title: 'orderManage.table.productPicList',
    dataIndex: 'productPicList',
    key: 'productPicList',
    width: 300,
    slots: { customRender: 'productPicList' }
  },
  {
    title: 'orderManage.table.orderSn',
    dataIndex: 'orderSn',
    key: 'orderSn',
    width: 150,
    slots: { customRender: 'orderSn' }
  },
  {
    title: 'orderManage.table.paymentType',
    dataIndex: 'paymentType',
    key: 'paymentType',
    width: 100,
    slots: { customRender: 'paymentType' }
  },
  {
    title: 'orderManage.table.dissentReceiptAt',
    dataIndex: 'dissentReceiptAt',
    key: 'dissentReceiptAt',
    width: 120,
    slots: { customRender: 'dissentReceiptAt' }
  },
  {
    title: 'orderManage.table.cancelAt',
    dataIndex: 'cancelAt',
    key: 'cancelAt',
    width: 100,
    slots: { customRender: 'cancelAt' }
  },
  {
    title: 'orderManage.table.quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 80,
    slots: { customRender: 'quantity' }
  },
  {
    title: 'orderManage.table.cancelQuantity',
    dataIndex: 'cancelQuantity',
    key: 'cancelQuantity',
    width: 80,
    slots: { customRender: 'cancelQuantity' }
  },
  {
    title: 'orderManage.table.deliveryQuantity',
    dataIndex: 'deliveryQuantity',
    key: 'deliveryQuantity',
    width: 80,
    slots: { customRender: 'deliveryQuantity' }
  },
  // {
  //   title: 'orderManage.table.dissentAmount',
  //   dataIndex: 'dissentAmount',
  //   key: 'dissentAmount',
  //   width: 120,
  //   slots: { customRender: 'dissentAmount' },
  // },
  {
    title: 'orderManage.table.amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 80,
    slots: { customRender: 'amount' }
  },
  {
    title: 'orderManage.table.shippingCount',
    dataIndex: 'shippingCount',
    key: 'shippingCount',
    width: 80,
    slots: { customRender: 'shippingCount' }
  },
  {
    title: 'orderManage.table.finishAt',
    dataIndex: 'finishAt',
    key: 'finishAt',
    width: 120,
    slots: { customRender: 'finishAt' }
  },
  {
    title: 'orderManage.table.expressFees',
    dataIndex: 'expressFees',
    key: 'expressFees',
    width: 120,
    slots: { customRender: 'expressFees' }
  },
  {
    title: 'orderManage.table.status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    slots: { customRender: 'status' }
  },
  {
    title: 'orderManage.table.cancelReason',
    dataIndex: 'cancelReason',
    key: 'cancelReason',
    width: 200,
    slots: { customRender: 'cancelReason' }
  },
  {
    title: 'orderManage.table.typeValue',
    dataIndex: 'typeValue',
    key: 'typeValue',
    width: 100,
    slots: { customRender: 'typeValue' }
  },
  {
    title: 'orderManage.table.receiptQuantity',
    dataIndex: 'receiptQuantity',
    key: 'receiptQuantity',
    width: 100,
    slots: { customRender: 'receiptQuantity' }
  },
  {
    title: 'orderManage.table.remark',
    dataIndex: 'remark',
    key: 'remark',
    width: 160,
    slots: { customRender: 'remark' }
  },
  {
    title: 'orderManage.table.operate',
    key: 'operate',
    align: 'center',
    slots: { customRender: 'operate' },
    width: 180,
    fixed: 'right'
  }
]
export function returnTableColumn(newValue) {
  let filterKeys = []
  if (newValue == '1') {
    filterKeys = ['productPicList', 'orderSn', 'paymentType', 'dissentReceiptAt', 'quantity', 'cancelQuantity', 'amount', 'expressFees', 'status', 'typeValue', 'remark', 'operate']
  }
  if (newValue == '2') {
    filterKeys = [
      'productPicList',
      'orderSn',
      'dissentReceiptAt',
      'amount',
      'expressFees',
      'dissentAmount',
      'quantity',
      'cancelQuantity',
      'deliveryQuantity',
      'receiptQuantity',
      'shippingCount',
      'typeValue',
      'remark',
      'operate'
    ]
  }
  if (newValue == '3') {
    filterKeys = [
      'productPicList',
      'orderSn',
      'dissentAmount',
      'amount',
      'expressFees',
      'quantity',
      'cancelQuantity',
      'deliveryQuantity',
      'receiptQuantity',
      'dissentReceiptAt',
      'finishAt',
      'shippingCount',
      'typeValue',
      'remark',
      'operate'
    ]
  }
  if (newValue == '4') {
    filterKeys = ['productPicList', 'orderSn', 'cancelAt', 'amount', 'expressFees', 'quantity', 'cancelQuantity', 'cancelReason', 'typeValue', 'remark', 'operate']
  }
  // columns.value = transformColumnsI18n(initialColumns.filter((it) => filterKeys.includes(it.key)));
  return filterKeys
}
